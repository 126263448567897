import { SolidStep } from '../shared/components/solid/solid-auth-vertical-steps/solid-auth-vertical-steps.component';

export const REGULAR_ONBOARD_STEPS: SolidStep[] = [
  {
    name: 'Create Account',
    description: 'Fill in your details to create a new account',
  },
  {
    name: 'Verify email',
    description: 'Check your inbox for a confirmation email',
  },
  {
    name: 'Personalize profile',
    description: 'Add a personal touch by setting up your profile details',
  },
  {
    name: 'Manage invitations',
    description:
      'Review and accept your invitation to join the team and start collaborating!',
  },
  {
    name: 'Account Ready',
    description: 'Take a guided tour to explore the app',
  },
];

export const INVITED_ONBOARD_STEPS: SolidStep[] = [
  {
    name: 'Activate your account',
    description: 'Set a password and read the terms and conditions',
  },
  {
    name: 'Personalize profile',
    description: 'Add a personal touch by setting up your profile details,',
  },
  {
    name: 'Account Ready',
    description: 'Take a guided tour to explore the app',
  },
];

export const LAST_ONBOARD_STEP: number = 3;
